var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("div", { staticClass: "mb-3 d-flex flex-column" }, [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("span", { staticClass: "primary--text font-weight-bold" }, [
                _vm._v("Snapshots")
              ]),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  icon: "",
                                  loading: _vm.fetchingSnapshots
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$store.dispatch(
                                      "instanceStore/fetchInstanceSnapshots",
                                      _vm.$route.params.iid
                                    )
                                  }
                                }
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("refresh")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Refresh snapshots")])]
              )
            ],
            1
          ),
          _vm.fetchingSnapshots
            ? _c("span", { staticClass: "caption text-uppercase" }, [
                _vm._v("Fetching instance data....")
              ])
            : _vm.nonDevelopmentSnapshots.length
            ? _c("span", { staticClass: "caption text-uppercase ma-0" }, [
                _vm._v(
                  _vm._s(_vm.nonDevelopmentSnapshots.length) +
                    " SNAPSHOTS FOUND"
                )
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "v-card-text",
        [
          _vm.nonDevelopmentSnapshots.length > 0 &&
          _vm.fetchingSnapshots === false
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "space-between" } },
                    _vm._l(Object.values(_vm.snapshotCheckboxBinders), function(
                      item,
                      index
                    ) {
                      return _c(
                        "v-col",
                        { key: index, attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-checkbox",
                                          _vm._g(
                                            {
                                              staticClass: "ma-0 pa-0",
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-center"
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    item.color
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fiber_manual_record"
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text caption text-uppercase"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.text
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: item.status,
                                                callback: function($$v) {
                                                  _vm.$set(item, "status", $$v)
                                                },
                                                expression: "item.status"
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_c("span", [_vm._v(_vm._s(item.description))])]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c("v-divider", { staticClass: "mb-5" }),
                  _vm.nonDevelopmentSnapshots.length > 0
                    ? _c(
                        "v-timeline",
                        { attrs: { reverse: "" } },
                        _vm._l(_vm.filteredSnapshots, function(snapshot) {
                          return _c(
                            "v-timeline-item",
                            {
                              key: snapshot.snid,
                              attrs: {
                                "fill-dot": true,
                                right: "",
                                color: _vm.dotColor(snapshot),
                                small: ""
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between flex-wrap",
                                  attrs: { slot: "opposite" },
                                  slot: "opposite"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("dateTimeToHuman")(
                                        snapshot.snapshot_timestamp
                                      )
                                    ) + " (UTC)"
                                  )
                                ]
                              ),
                              _c(
                                "v-card",
                                { staticClass: "elevation-1" },
                                [
                                  _c("v-card-title", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                        staticStyle: { width: "100%" }
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticStyle: {
                                              "text-decoration": "none"
                                            },
                                            attrs: {
                                              to: {
                                                name: "snapshot-overview",
                                                params: {
                                                  oid: _vm.$route.params.oid,
                                                  sid: _vm.$route.params.sid,
                                                  iid: _vm.$route.params.iid,
                                                  snid: snapshot.snid
                                                }
                                              }
                                            }
                                          },
                                          [
                                            _c("v-hover", {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var hover = ref.hover
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            class: [
                                                              hover
                                                                ? "secondary--text"
                                                                : "primary--text",
                                                              "mr-2",
                                                              "subtitle-1",
                                                              "font-weight-bold"
                                                            ]
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                snapshot.long_id
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  color: snapshot.filesystem_prefix
                                                                    ? "success"
                                                                    : "info",
                                                                  small: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getSnapshotStatusIcon(
                                                                    snapshot
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "max-width": "500px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getSnapshotStatusDescription(
                                                            snapshot
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm.isInstanceEditor === true
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center"
                                              },
                                              [
                                                !_vm.isDistributedInstance
                                                  ? _c(
                                                      "SnapshotRestoreDialog",
                                                      {
                                                        attrs: {
                                                          snapshotData: snapshot,
                                                          isRestoreToCurrentInstance: true
                                                        }
                                                      }
                                                    )
                                                  : _vm._e(),
                                                !_vm.isDistributedInstance
                                                  ? _c(
                                                      "SnapshotRestoreDialog",
                                                      {
                                                        attrs: {
                                                          snapshotData: snapshot,
                                                          isRestoreToCurrentInstance: false
                                                        }
                                                      }
                                                    )
                                                  : _vm._e(),
                                                _c("SnapshotDeleteDialog", {
                                                  attrs: {
                                                    snid: snapshot.snid.toString()
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("v-card-text", [
                                    _vm._v(
                                      " " + _vm._s(snapshot.description) + " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm.fetchingSnapshots
            ? _c(
                "div",
                _vm._l(
                  [
                    { id: "c1", opacity: 1 },
                    { id: "c2", opacity: 0.75 },
                    { id: "c3", opacity: 0.5 }
                  ],
                  function(item) {
                    return _c("div", { key: item.id }, [
                      _c(
                        "div",
                        { style: { opacity: item.opacity } },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: { type: "list-item-three-line" }
                          })
                        ],
                        1
                      )
                    ])
                  }
                ),
                0
              )
            : _vm.fetchingSnapshots === false &&
              _vm.nonDevelopmentSnapshots.length === 0
            ? _c(
                "v-banner",
                { attrs: { "two-line": "" } },
                [
                  _c(
                    "v-avatar",
                    {
                      attrs: { slot: "icon", color: "white", size: "60" },
                      slot: "icon"
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { "x-large": "", icon: "info", color: "info" }
                        },
                        [_vm._v(" info ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column",
                      staticStyle: { "max-width": "900px" }
                    },
                    [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("No snapshots found")
                      ]),
                      _c("span", { staticClass: "mt-1" }, [
                        _vm._v(
                          " Snapshots are immutables states of previous work that allow you to reproduce results and restore files, applications, and data. Check the "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://docs.nuvolos.cloud/getting-started/working-with-snapshots/create-a-snapshot",
                              target: "_blank"
                            }
                          },
                          [_vm._v("documentation here")]
                        ),
                        _vm._v(" for information on how to create a snapshot. ")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }